import { useState, useEffect } from 'react';

import Section from "../../components/Section";
import { userMedia } from '../../GraphAPI';
import { getWindowDimensions } from '../../CustomHooks';

import './styles.css';

function handle(item, index) {

    let imageUrl = item.media_url;;
    if ("VIDEO" === item.media_type) {
        imageUrl = item.thumbnail_url;
    }

    return (
        <a key={index} href={item.permalink} target="_blank" className="section-instagram-post">
            <div className="section-instagram-post-image">
                <img src={imageUrl} />
                <div className="section-instagram-backdrop">
                    <span>{item.caption}</span>
                </div>
            </div>
        </a>
    )
}

function InstagramSection() {

    const [medias, setMedias] = useState([]);

    useEffect(_ => {

        // Não executar em mobile
        if (getWindowDimensions().width > 800) {
            userMedia()
                .then(response => response.text())
                .then(result => {
                    const responseJson = JSON.parse(result)

                    let data = responseJson.data;
                    if (data.length > 12) {
                        data = data.slice(0, 12);
                    }

                    setMedias(data);
                })
                .catch(error => {
                    console.log('error', error);
                });
        }
    }, []);

    return (
        <Section id="instagram" className="section-instagram">
            <h2>Me siga no Instagram<span className="green-dot">.</span></h2>
            <a
                className="section-instagram-link"
                href="https://www.instagram.com/drabarbaraclemente/"
                target="_blank"
            >
                @drabarbaraclemente
            </a>
            <div className="section-instagram-posts">
                {medias.map((item, index) => handle(item, index))}
            </div>
        </Section>
    )
}
export default InstagramSection;