import RoundButton from "../../components/RoundButton";
import Section from "../../components/Section";

import './styles.css';

function MissoesSociaisSection() {
    return (
        <Section id="missoes_sociais" className="section-missoes-sociais">
            <h2>Missões Sociais<span className="blue-dot">.</span></h2>
            <div className="section-missoes-sociais-content">
                <div className="section-missoes-sociais-content-1">
                    <p>Segundo a Organização Mundial da Saúde, 80% dos casos de cegueira poderiam ter sido evitados.</p>
                    <RoundButton href="/missoes_sociais" text="Mais detalhes" />
                </div>
                <div className="section-missoes-sociais-content-2">
                    <p>Realizei um treinamento no setor de catarata focado em técnicas cirúrgicas na Índia (Prasad Eye Institute) e participei e coordenei um projeto da Oftalmologia Humanitária como cirurgiã de catarata atendendo a população de cidades ribeirinhas no Amazonas.</p>
                    <p>O que vi na minha estadia na Índia foi que o Prasad Eye Institute dá um exemplo único de como os profissionais trabalham profundamente para criar um ambiente humanizado, e essa é a melhor inspiração para todas as experiências profissionais que alguém pode ter.</p>
                    <p>Voltei ao Brasil com uma nova visão de mundo para aplicar em nossos projetos sociais que visam alcançar pessoas que realmente precisam de nossa ajuda e estão longe de profissionais qualificados.</p>
                </div>
            </div>
        </Section>
    )
}
export default MissoesSociaisSection;