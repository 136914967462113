import imgLogoExpedicao from '../../img/logo-expedicao-amazonia.jpg';
import imgMissao1 from '../../img/missoes-barbara-1.jpg';
import imgMissao2 from '../../img/missoes-barbara-2.jpg';
import imgMissao3 from '../../img/missoes-barbara-3.jpg';

import Section from '../../components/Section';
import RoundButton from '../../components/RoundButton';

import '../styles.css'
import './styles.css'

export default function CuidadoAoIdoso() {
    return (
        <div className="page missoes-sociais">

            <Section className="banner-style1">
                <h1>Missões <strong>sociais</strong></h1>
            </Section>

            <Section className="oftalmologia-humanitaria">
                <h2 className="underlined">Oftalmologia Humanitária</h2>
                <div>
                    <div>
                        <div>
                            <img src={imgLogoExpedicao} />
                            <h3>Expedição Amazônica <span>Calha do Rio Madeira</span></h3>
                        </div>
                        <div>
                            Cirurgia de Catarata, atendimentos clínicos e doação de lupas para leitura.
                        </div>
                    </div>
                    <div>
                        <p>
                            Em 2019, fiz parte de um grupo de Oftalmologia Humanitária com o Instituto da Visão - IPEPO como cirurgiã de catarata. O Projeto Amazônico Oftalmologia Humanitária oferece a populações de cidades ribeirinhas no Amazonas o acesso a cirurgias de catarata e pterígio - doenças oftalmológicas comuns em cidades tropicais e em trabalhadores de lavoura e campo, devido à exposição aos raios ultravioleta. Além das cirurgias, também foi feita a doação de óculos aos pacientes da região, que passaram pela avaliação de acuidade visual, proporcionando melhor visão também a pacientes que não tinham indicação cirúrgica, mas que estavam com dificuldade de enxergar.
                        </p>
                        <p>
                            Esta ação social concedeu ao IPEPO o Prêmio António Champalimaud de Visão 2019, o mais importante reconhecimento da oftalmologia mundial. Participar da expedição do projeto Oftalmologia Humanitária me fez enxergar a vida com outro olhar. É indescritível essa oportunidade de fazer o bem e atingir as pessoas, que não tem acesso à saúde ocular com grandes profissionais da oftalmologia.
                        </p>
                    </div>
                </div>
            </Section>

            <Section className="oftalmologia-humanitaria-fotos">
                <h3>Veja abaixo algumas fotos do projeto:</h3>
                <div>
                    <div>
                        <div><img src={imgMissao1} /></div>
                        <div><img src={imgMissao2} /></div>
                        <div><img src={imgMissao3} /></div>
                    </div>
                    <p>Meu objetivo como médica, além <strong>de transformar a visão dos pacientes</strong> é, de alguma forma, poder <strong>proporcionar qualidade de visão a pessoas que não têm acesso.</strong></p>
                </div>
            </Section>

            <Section className="cta">
                <h3>Saúde ocular é um direito de todos</h3>
                <RoundButton
                    href={process.env.REACT_APP_WHATSAPP}
                    text="Agende uma consulta" />
            </Section>

        </div>
    );
}