import Section from "../../components/Section";

import './styles.css'

function FooterSection(props) {
    return (
        <Section id="copyright" className="section-copyright">
            <span>© Calisto Consulting - 2021.</span>
            <span>Todos os direitos reservados.</span>
        </Section>
    )
}
export default FooterSection;