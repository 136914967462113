import { Link } from 'react-router-dom';

import './styles.css'

function renderContent(props) {

    const img = props.imgSrc && <div><img src={props.imgSrc} /></div>;

    return (
        <>
            {img}
            <div>
                <div className="round-button-text">
                    {props.text}
                </div>
            </div>
        </>
    )
}

function RoundButton(props) {

    if (props.target || props.href && props.href.indexOf("/") !== 0) {
        return (
            <div className={"round-button"}>
                <a href={props.href} target={props.target || "_blank"}>
                    {renderContent(props)}
                </a>
            </div>
        )
    }

    return (
        <div className={"round-button"}>
            <Link to={props.href}>
                {renderContent(props)}
            </Link>
        </div>
    )
}

export default RoundButton;