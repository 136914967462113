import Section from '../../components/Section';

//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import './styles.css';

function MenuSection(props) {

    const hidden = props.hidden || false;

    let classNames = "menu";
    if (hidden) {
        classNames += " hidden";
    }

    return (
        <Section className={classNames} {...props}>
            <span onClick={props.toggleMenu}>x</span>
            <ul>
                <li>
                    <Link onClick={props.toggleMenu} to="/quem_sou">Quem sou eu</Link>
                </li>
                <li>
                    <Link onClick={props.toggleMenu} to="/missoes_sociais">Missões sociais</Link>
                </li>
                <li>
                    <Link onClick={props.toggleMenu} to="/#especialidades">Especialidades</Link>
                </li>
                <li>
                    <Link onClick={props.toggleMenu} to="/blog">Blog</Link>
                </li>
            </ul>
        </Section>
    )
}

export default MenuSection;