import './styles.css';

function MenuIcon(props) {
    return (
        <div className="menu_icon" {...props}>
            <div>
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}
export default MenuIcon;