import RoundButton from '../../components/RoundButton';
import Section from '../../components/Section';

import './styles.css';

function AgendeSuaConsultaSection() {
    return (
        <Section id="agende_sua_consulta" className="section-agende-sua-consulta">
            <h2>Agende sua consulta comigo hoje mesmo<span className="green-dot">.</span></h2>
            <div>As consultas são marcadas diretamente por WhatsApp com a minha equipe, tudo de forma rápida e segura.</div>
            <RoundButton href={process.env.REACT_APP_WHATSAPP} text="Clique aqui" />
        </Section>
    )
}
export default AgendeSuaConsultaSection;