import RoundButton from "../../components/RoundButton";
import Section from "../../components/Section";

import './styles.css';

function EspecialidadesSection() {

    return (
        <Section id="especialidades" className="section-especialidades">
            <h2>Especialidades<span className="blue-dot">.</span></h2>
            <div className="section-especialidades-itens">
                <div>
                    <span>Orientação ao Idoso</span>
                    <RoundButton href="/cuidado_ao_idoso" text="Saiba mais" />
                </div>
                <div>
                    <span>Oftalmologia Esportiva</span>
                    <RoundButton href="/oftalmologia_esportiva" text="Saiba mais" />
                </div>
                <div>
                    <span>Cirurgia Refrativa</span>
                    <RoundButton href="/cirurgia_refrativa" text="Saiba mais" />
                </div>
            </div>
        </Section>
    );
}

export default EspecialidadesSection;