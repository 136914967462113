import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Section from "../../components/Section";

import { fetchPost } from '../../wordpress/api';

import '../styles.css'
import './styles.css'

export default function BlogPostDetails() {

    const { id } = useParams()
    const history = useHistory()
    const [post, setPost] = useState(null)

    useEffect(_ => {
        fetchPost(id)
            .then(response => {
                setPost(response)
            });
    }, [])

    if (!post) return null

    return (
        <div className="page blog-post-detail">

            <Section className="blog-post-header">
                <div className="columns">
                    <div className="left_column">

                        <h1 className="underlined"><strong>Blog</strong><span className="green-dot">.</span></h1>

                        <div>
                            <h2><strong>{post.title}</strong></h2>

                            <div className="data-publicacao">
                                Postado em: {new Intl.DateTimeFormat('pt-BR').format(new Date(post.date))}
                            </div>
                        </div>
                    </div>
                    <div className="right_column">
                        <img src={post.imageUrl} />
                    </div>
                </div>
            </Section>

            <Section className="blog-post-content">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: post.content }}>
                    </div>
                </div>
            </Section>

            <Section className="blog-post-goback">
                <div>
                    <div onClick={_ => history.goBack()}>Voltar</div>
                </div>
            </Section>

        </div>
    )
}