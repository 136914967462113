import imgPRK from '../../img/PRK.jpg';
import imgLasik from '../../img/LASIK.jpg';

import Section from '../../components/Section';
import RoundButton from '../../components/RoundButton';

import '../styles.css'
import './styles.css'

export default function CirurgiaRefrativaPage() {
    return (
        <div className="page especialidade-cirurgia-refrativa">

            <Section className="banner-style1">
                <h1>Cirurgia <strong>refrativa</strong></h1>
            </Section>

            <Section className="adeus-oculos">
                <h2 className="underlined">Adeus óculos!</h2>
                <div className="columns">
                    <div className="left_column">
                        <p>
                            A cirurgia refrativa tem o objetivo de corrigir erros refrativos, <strong>proporcionando maior liberdade de óculos.</strong>
                        </p>
                        <p className="italic">
                            Ela consiste em corrigir a curvatura e espessura da córnea através da aplicação do laser por meio de uma raspagem ou abertura, dependendo do procedimento.
                        </p>
                    </div>
                    <div className="right_column">
                        <p>
                            São três tipos de procedimentos para eliminar o uso de óculos dos pacientes, são eles: Lasik, PRK. Cada um tem uma recomendação própria e específica que é feita mediante o resultado de exames pré-operatórios solicitados pelo médico no dia da consulta de avaliação.
                        </p>
                        <div className="images">
                            <div>
                                <div>
                                    <img src={imgPRK} />
                                    <span>PRK</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <img src={imgLasik} />
                                    <span>LASIK</span>
                                </div>
                            </div>
                        </div>
                        <p>
                            A principal diferença entre elas é a forma da correção e aplicação. Enquanto no Lasik, a aplicação do laser é feito mediante a uma abertura na superfície da córnea é indicado para situações em que não há anormalidade da córnea. No PRK a aplicação do laser é feita mediante a uma raspagem na superfície da córnea é indicado para pacientes que apresentam córnea fina e pequenas alterações na mesma.
                        </p>
                    </div>
                </div>
            </Section>

            <Section className="qualidade-de-vida">
                <h2 className="underlined">Qualidade de vida do paciente</h2>
                <div>
                    <p>Essa cirurgia tem mostrado bons resultados em termos de qualidade de vida do paciente. Apesar de ser uma cirurgia considerada de baixa complexidade, é preciso preencher alguns pré requisitos para a realização da mesma, como:</p>
                    <ul>
                        <li>Ter mais de 21 anos,</li>
                        <li>Não possuir nenhuma patologia ocular pré-existente</li>
                        <li>Ter o grau estabilizado.</li>
                    </ul>
                    <p>A indicação e recomendação da cirurgia é feita mediante a exames pré-operatórios que indicam a condição da córnea. A decisão é tomada junto ao cirurgião após o resultado dos exames.</p>
                    <p>Os benefícios da cirurgia são incontáveis, principalmente quando o paciente é resistente ao uso do óculos, mas é totalmente dependente dele para enxergar. Essa especialidade de cirurgia refrativa busca proporcionar qualidade de vida e de visão para o paciente, promovendo saúde e bem-estar.</p>
                </div>
            </Section>

            <Section className="cta">
                <h2>Quer saber se você é elegível para a cirurgia refrativa?</h2>
                <RoundButton
                    href={process.env.REACT_APP_WHATSAPP}
                    text="Entre em contato" />
            </Section>

        </div >
    );
}