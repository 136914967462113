import RoundButton from '../../components/RoundButton';
import Section from '../../components/Section';

import imgFuncionarios from '../../img/Dra-Barbara-Clemente-Funcionarios.png';
import iconeCurriculo from '../../img/icone-curriculo.png';

import iconeAjudar from '../../img/icone-ajudar.png';
import iconeOrientar from '../../img/icone-orientar.png';
import iconePrevenir from '../../img/icone-prevenir.png';
import './styles.css'

function QuemSouPage() {
    return (
        <div className="page">

            <Section className="section-sobre-mim">
                <div>
                    <h1>Sobre mim</h1>
                    <p>
                        Sou a Dra. Bárbara Clemente, médica formada pela PUC SP, com residência em oftalmologia no
                        Hospital de Olhos (HOA) - Goiânia e especialização em Catarata pelo Instituto da Visão IPEPO.
                        Ao longo do meu processo de especialização, realizei um treinamento no setor de catarata
                        focado em técnicas cirúrgicas na Índia (Prasad Eye Institute) e coordenei um projeto da
                        Oftalmologia Humanitária como cirurgiã de catarata que atende a população de cidades
                        ribeirinhas no Amazonas.
                    </p>
                    <h2>"<strong>Transformando olhares</strong> através da saúde"</h2>
                    <h3>Currículo lattes completo:</h3>
                    <RoundButton imgSrc={iconeCurriculo} text="Acesse" />
                </div>
            </Section>

            <Section className="section-minha-tragetoria">
                <div className="section-minha-tragetoria-foto">
                    <img src={imgFuncionarios} />
                </div>
                <div className="section-minha-tragetoria-texto">
                    <h2>Minha tragetória</h2>
                    <p>Desde que me formei, sempre quis ser um caminho para a solução dos problemas dos pacientes. Meus projetos vão além do atendimento, pois entendo que antes de atender, o paciente precisa saber o porquê ele precisa ser atendido. Por isso, realizo curadoria de textos médicos, produzo conteúdos informativos e de rápida absorção nas minhas redes sociais, dou entrevista nos jornais da cidade que atendo, sou colunista no principal jornal de São Joaquim da Barra, pois o conhecimento é um estímulo para a prevenção!</p>
                    <p>Através dessas ações quero que meus pacientes tenham conhecimento para cuidarem melhor de sua saúde ocular. Um dos meus objetivos como médica oftalmologista são:</p>
                    <ul className="icones">
                        <li>
                            <div>
                                <img src={iconeAjudar} />
                            </div>
                            <div>Ajudar</div>
                        </li>
                        <li>
                            <div>
                                <img src={iconeOrientar} />
                            </div>
                            <div>Orientar</div>
                        </li>
                        <li>
                            <div>
                                <img src={iconePrevenir} />
                            </div>
                            <div>Prevenir</div>
                        </li>
                    </ul>
                </div>
            </Section>

            <Section className="section-clinica-santa-luzia">
                <h2>A Clínica Santa Luzia</h2>
                <p>A clínica Santa Luzia oferece atendimento humanizado desde 1992,
                    fundada pelo meu pai, Dr. José Roberto Clemente médico oftalmologista.
                    A tradição passou de pai para filha e ao longo destes anos, buscamos cuidar dos
                    pacientes  de forma humanizada, oferecendo uma medicina democrática, acessível,
                    promovendo a saúde ocular e a melhor assistência à comunidade de São Joaquim da
                    Barra e região.
                </p>
            </Section>
{/*
            <Container fluid id="logo_clinica">
                <Container>
                </Container>
            </Container>
*/}
            <Section className="section-cta-ja-fez-checkup">
                <RoundButton
                    href={process.env.REACT_APP_WHATSAPP}
                    text="Já fez seu check up este ano?" />
            </Section>

        </div>
    );
}

export default QuemSouPage;