import AboutSection from '../sections/AboutSection';
import EspecialidadesSection from '../sections/EspecialidadesSection';
import MissoesSociaisSection from '../sections/MissoesSociaisSection';
import AgendeSuaConsultaSection from '../sections/AgendeSuaConsultaSection';
import BlogSection from '../sections/BlogSection';
import InstagramSection from '../sections/InstagramSection';

function RootPage() {
    return (
        <div className="page">
            <AboutSection />
            <EspecialidadesSection />
            <MissoesSociaisSection />
            <AgendeSuaConsultaSection />
            <BlogSection />
            <InstagramSection />
        </div>
    );
}

export default RootPage;