import imgTristeza from '../../img/Cuidado-ao-idoso-Tristeza.png';
import imgDificuldade from '../../img/Cuidado-ao-idoso-dificuldade-em-realizar-tarefas.png';
import imgMedo from '../../img/Cuidado-ao-idoso-medo.png';
import imgQuedas from '../../img/Cuidado-ao-idoso-quedas-e-tropeços.png';

import Section from '../../components/Section';
import RoundButton from '../../components/RoundButton';

import '../styles.css'
import './styles.css'

export default function CuidadoAoIdoso() {
    return (
        <div className="page especialidade-cuidado-ao-idoso">

            <Section className="banner-style1">
                <h1>Cuidado ao <strong>idoso</strong></h1>
            </Section>

            <Section className="orientacao-ao-idoso">
                <h2 className="underlined">Orientação e cuidado com o idoso</h2>
                <div className="columns">
                    <div className="left_column">
                        <p>
                            Com o <strong>avanço da idade</strong>, problemas oftalmológicos podem surgir, <strong>comprometendo a saúde ocular</strong> e qualidade de vida de pacientes idosos.
                        </p>
                        <p className="italic">
                            Isso acontece porque nossos olhos também passam pelo processo de envelhecimento e, a cada ano adquirido, as estruturas dos olhos se alteram, provocando assim o envelhecimento da visão.
                        </p>
                    </div>
                    <div className="right_column">
                        <p>
                            Na fase adulta, os olhos começam a apresentar sintomas de que a visão já não está tão boa como antes, impactando na qualidade da visão. Isso costuma acontecer por volta dos 40 anos. A primeira alteração acomete o cristalino - mesmo lugar onde acontece a catarata (doença mais comum em idosos).
                        </p>
                        <p>
                            O cristalino é uma lente natural responsável pela formação da nitidez da imagem. Para que ele tenha essa nitidez, o cristalino ajusta seu tamanho mediante a distância do foco. Com o passar dos anos, o cristalino perde sua elasticidade e os olhos perdem a capacidade de ajustar o foco para perto, causando a sensação de vista cansada, característica comum da presbiopia.
                        </p>
                        <p className="blue">
                            Depois disso, os problemas mais comuns que acometem os idosos são:
                        </p>
                        <ul>
                            <li><span>Catarata</span> - Doença mais comum na terceira idade, onde o cristalino perde sua transparência, tornando-se torna opaco e, aos poucos, o paciente acometido vai perdendo qualidade de visão e qualidade de vida.</li>
                            <li><span>Degeneração macular relacionada à idade</span> - Doença que afeta a retina, comprometendo a visão central do paciente acometido é a causa mais comum de perda da visão em idosos.</li>
                            <li><span>Glaucoma</span> - O glaucoma é a segunda maior causa de cegueira irreversível do mundo. Trata-se de uma doença que causa dano no nervo óptico e de difícil diagnóstico pois, na maioria dos casos, o glaucoma é silencioso e os sintomas aparecem quando o estágio da doença está avançado.</li>
                        </ul>
                    </div>
                </div>
            </Section>

            <Section className="como-identificar">
                <h2 className="underlined">Como identificar que um idoso precisa de ajuda?</h2>
                <div>
                    <p>Nem todos os idosos relatam incômodos com a sua visão, seja por vergonha ou medo e, por isso, é de extrema importância que seja dada a devida atenção para alterações comportamentais, pois segundo pesquisas, há um forte indício de que a depressão e insegurança estão diretamente relacionados a problemas de visão no idoso. Atente-se a alterações comportamentais como:</p>
                    <ul>
                        <li>
                            <img src={imgTristeza} />
                            <span>Tristeza</span>
                        </li>
                        <li>
                            <img src={imgDificuldade} />
                            <span>Dificuldade em realizar tarefas sozinho</span>
                        </li>
                        <li>
                            <img src={imgMedo} />
                            <span>Medo</span>
                        </li>
                        <li>
                            <img src={imgQuedas} />
                            <span>Quedas e tropeços frequentes</span>
                        </li>
                    </ul>
                    <p>Os idosos precisam ser avaliados pelo médico oftalmologista pelo menos 2x por ano, pois as alterações visuais nessa fase são mais frequentes e precisam de atenção. Cuidadores de idosos e familiares devem estar atentos aos sintomas.</p>
                </div>
            </Section>

            <Section className="cta">
                <h2>Envelhecer com visão é possível!</h2>
                <RoundButton
                    href={process.env.REACT_APP_WHATSAPP}
                    text="Agende uma consulta familiar" />
            </Section>

        </div >
    );
}