import { useState } from 'react';
import Section from '../../components/Section';
import RoundButton from '../../components/RoundButton';

import './styles.css';

function ContatoSection() {

    const [contatoForm, setContatoForm] = useState({
        nome: ''
        , contato: ''
        , assunto: ''
        , mensagem: ''
    });

    const handleChange = name => ev => {
        const f = { ...contatoForm };
        f[name] = ev.target.value;
        setContatoForm(f);
    }

    const sendForm = ev => {

        let url = `${process.env.REACT_APP_BASE_URL}/wordpress-custom/mail.php`;

        fetch(url
            , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contatoForm)
            })
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                console.error(err);
            });

        ev.preventDefault();
    }

    return (
        <Section id="contato" className="section-contato">

            <div className="section-contato-form">
                <h2>Contato<span className="green-dot">.</span></h2>
                <div>Fale com nossa equipe para tirar dúvidas, sugestões ou reclamações.</div>
                <form>
                    <input type="text" placeholder="Nome" value={contatoForm['nome']} onChange={handleChange('nome')} />
                    <input type="text" placeholder="E-mail ou Telefone" value={contatoForm['contato']} onChange={handleChange('contato')} />
                    <input type="text" placeholder="Assunto" value={contatoForm['assunto']} onChange={handleChange('assunto')} />
                    <textarea rows="10" onChange={handleChange('mensagem')} value={contatoForm['mensagem']} />
                    <RoundButton onClick={sendForm} text="Enviar" />
                </form>
            </div>

            <div className="section-contato-info">
                <h2>Venha nos visitar!</h2>
                <iframe
                    className="section-contato-info-map"
                    loading="lazy"
                    allowFullScreen=""
                    src="https://maps.google.com/maps?q=Rua%20Piratininga,%201178%20Cep%2014600-000%20-%20S%C3%A3o%20Paulo/SP%20-%20Brasil&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0">
                </iframe>
                <div className="section-contato-info-address">
                    <div>Rua Piratininga, 1178 Bairro Vila Deieno</div>
                    <div>Cep 14600-000 - São Paulo/SP - Brasil</div>
                    <div>Cidade São Joaquim da Barra – SP</div>
                </div>
                <div className="section-contato-info-phones">
                    <div>Telefones:</div>
                    <div>(016) 3818-1988</div>
                </div>
            </div>
        </Section>
    )
}
export default ContatoSection;