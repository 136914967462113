import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { fetchPosts } from '../../wordpress/api';

import Section from '../../components/Section';

import '../styles.css'
import './styles.css'

export default function Blog() {

    const [posts, setPosts] = useState([])
    const [page, setPage] = useState(1)
    const postsPerPage = 6
    const [totalPages, setTotalPages] = useState(0)

    useEffect(_ => {
        fetchPosts(postsPerPage, page)
            .then(response => {
                setTotalPages(response.totalPages)
                setPosts(response.data)
            });
    }, [page])

    const previousPage = _ => {
        if (page > 1)
            setPage(page - 1)
    }

    const nextPage = _ => {
        if (page < totalPages)
            setPage(page + 1)
    }

    return (
        <div className="page blog">

            <Section className="banner-style1">
                <h1><strong>Blog</strong></h1>
            </Section>

            <Section className="blog-posts">
                <h2 className="underlined">Posts mais recentes<span className="green-dot">.</span></h2>

                <div className="blog-posts-recentes">
                    {posts.map((post, index) => (
                        <Link to={"/blog/post/" + post.id}>
                            <div key={index}>
                                <div><img src={post.imageUrl} /></div>
                                <div><span>{post.title}</span></div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="blog-posts-pagination">
                    {page > 1 && <span onClick={previousPage}>&#60; Mais novos</span>}
                    {page < totalPages && <span onClick={nextPage}>Mais antigos &#62;</span>}
                </div>
            </Section>

        </div>
    )
}