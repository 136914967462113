import Section from "../../components/Section";

import icon_facebook from '../../img/Facebook-BarbaraClemente.png';
import icon_instagram from '../../img/Instagram-BarbaraClemente.png';
import icon_linkedin from '../../img/LinkedIn-BarbaraClemente.png';

import './styles.css';

function MinhasRedes() {
    return (
        <Section className="section-minhas-redes">
            <h2>Minhas redes<span className="green-dot">.</span></h2>
            <div className="section-minhas-redes-icons">
                <a target="_blank" href="https://www.linkedin.com/in/bárbara-clemente-950651157/">
                    <img src={icon_linkedin} />
                </a>
                <a target="_blank" href="https://www.facebook.com/drabarbaraclemente/">
                    <img src={icon_facebook} />
                </a>
                <a target="_blank" href="https://www.instagram.com/drabarbaraclemente/">
                    <img src={icon_instagram} />
                </a>
            </div>
        </Section>
    )
}
export default MinhasRedes;