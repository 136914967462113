import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from '../../components/MenuIcon';
import RoundButton from '../../components/RoundButton';
import Section from '../../components/Section';
import agende_sua_consulta from '../../img/Icone-AgendeSuaConsulta.png';
import logo_completo from '../../img/logo-topo-Completo.png';
import logo_olho from '../../img/logo-topo-Olho.png';

import { getWindowDimensions } from '../../CustomHooks';

import './styles.css';

function HeaderSection(props) {

    const [sectionTopPosition, setSectionTopPosition] = useState(null)
    const [isMobile, setMobile] = useState(false)
    const [isOnTop, setOnTop] = useState(true)
    const [sectionClassNames, setSectionClassNames] = useState("section-header")

    useEffect(_ => {
        setMobile(getWindowDimensions().width < 800)
    }, [])

    useEffect(_ => {
        if (isMobile) {
            if (isOnTop)
                setSectionClassNames("section-header")
            else
                setSectionClassNames("section-header minidiv")
        }
    }, [isOnTop])

    useEffect(_ => {

        if (isMobile) {
            function scrollFunction() {
                if (document.documentElement.scrollTop >= 180) {
                    setSectionTopPosition(0)
                }
                else if (document.documentElement.scrollTop > 120) {
                    setOnTop(false)
                    let top = document.documentElement.scrollTop - 120
                    top = -60 + top
                    setSectionTopPosition(top)
                } else {
                    setOnTop(true)
                    setSectionTopPosition(null)
                }
            }

            window.onscroll = scrollFunction;
        }
    }, [isMobile])

    return (
        <Section
            id="header"
            className={sectionClassNames}
            style={sectionTopPosition !== null ? { top: sectionTopPosition + 'px' } : null}
        >
            <div className="section-header-menu">
                <MenuIcon onClick={props.toggleMenu} />
            </div>
            <div>
                <Link to="/">
                    <div id="logo">
                        {
                            !isMobile || isOnTop ?
                                <img id="logo_completo" src={logo_completo} />
                                :
                                <img id="logo_olho" src={logo_olho} />
                        }
                    </div>
                </Link>
            </div>
            <div>
                <RoundButton
                    href={process.env.REACT_APP_WHATSAPP}
                    imgSrc={agende_sua_consulta}
                    text="Agende sua consulta"
                    target="_blank"
                />
            </div>
        </Section>
    )
}

export default HeaderSection;