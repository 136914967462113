import './styles.css'

export default function Section(props) {

    const id = props.id || null
    const classNames = ["section"]
    if (props.className) {
        classNames.push(props.className)
    }
    const style = props.style

    return (
        <section id={id} className={classNames.join(" ")} style={style}>
            <div>
                {props.children}
            </div>
        </section>
    )
}