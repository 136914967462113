import Section from '../../components/Section';
import RoundButton from '../../components/RoundButton';

import '../styles.css'
import './styles.css'

export default function OftalmologiaEsportivaPage() {
    return (
        <div className="page especialidade-oftalmologia-esportiva">

            <Section className="banner-style1">
                <h1>Oftalmologia <strong>esportiva</strong></h1>
            </Section>

            <Section className="cuidados-no-esporte">
                <h2 className="underlined">Cuidados com os olhos no esporte</h2>
                <div className="columns">
                    <div className="left_column">
                        <p>
                            Atualmente fala-se muito sobre cuidado com saúde, corpo e mente e o esporte tem relação com esses três elementos.
                        </p>
                        <p>
                            Todas as <strong>práticas esportivas exigem cuidado e proteção</strong>, mas nem sempre os olhos recebem a atenção devida para esse hábito.
                        </p>
                        <p className="italic">
                            Na oftalmologia esportiva, o cuidado e preservação da visão e saúde ocular é tão essencial quanto a realização correta dos exercícios físicos, e cada esporte tem a sua particularidade.
                        </p>
                    </div>
                    <div className="right_column">
                        <p>A oftalmologia esportiva tem o objetivo de ajudar praticantes de esportes a preservarem sua saúde ocular como um todo. Em alguns tipos de esportes, recomenda-se a realização da cirurgia refrativa- cirurgia que corrige erros refrativos como miopia, astigmatismo e hipermetropia. Essa cirurgia proporciona a independência de óculos e maior liberdade para o paciente, melhorando sua performance tanto no esporte como no dia a dia.</p>
                        <h3>Esportes Aquáticos</h3>
                        <p>A água possui alto índice de contaminação, podendo provocar alergias e irritações oculares, principalmente em usuários de lentes de contato, portanto, a recomendação é não utilizar lentes para praticar esportes aquáticos. Os óculos de proteção não possuem boa vedação, tornando possível o contato dos olhos com a água contaminada.</p>
                        <p>Para casos como este, a melhor alternativa é o uso do óculos de natação com grau. Os componentes encontrados na água de piscina e mar podem danificar o material da lente e exigem que a limpeza seja feita rigorosamente, a fim de evitar o desenvolvimento de alergias, por isso, o recomendado é não utilizá-las nesse esporte.</p>
                        <p>Para esportes como o surf, a recomendação médica ideal é a utilização de lentes de descarte diário. Elas não impedem as irritações, mas diminuem as incidências.</p>
                        <h3>Esportes ao ar livre</h3>
                        <p>Os esportes ao ar livre expõem os praticantes a agentes externos e aos raios ultravioleta. A oftalmologia esportiva orienta que essa combinação pode provocar alterações oculares e desenvolver alguns problemas oftalmológicos que podem ser evitados com o uso de óculos de proteção UV. O uso adequado de óculos se torna uma barreira contra os raios e traumas oculares que podem ser causados por agentes externos que possam entrar em contato com o globo ocular.</p>
                        <h3>Esportes de contato físico</h3>
                        <p>Esse tipo de esporte é o que tem maior risco de traumas nos olhos. Pancadas na região ocular podem provocar doenças como catarata e descolamento de retina. Aqui a recomendação é evitar ao máximo esse tipo de pancada na região ocular, pois em alguns casos pode haver complicações e até mesmo a perda da visão.</p>
                    </div>
                </div>
            </Section>

            <Section className="cta">
                <h2>Baixe meu e-book exclusivo sobre os cuidados com os olhos na prática esportiva</h2>
                <RoundButton
                        text="Pegue o seu" />
            </Section>

        </div >
    );
}