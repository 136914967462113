import { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import HeaderSection from './sections/HeaderSection';
import ContatoSection from './sections/ContatoSection';
import MinhasRedesSection from './sections/MinhasRedesSection';
import FooterSection from './sections/FooterSection';
import MenuSection from './sections/MenuSection';

import RootPage from './pages/RootPage';
import QuemSouPage from './pages/QuemSouPage';
import CuidadoAoIdosoPage from './pages/Especialidades/CuidadoAoIdosoPage';
import OftalmologiaEsportivaPage from './pages/Especialidades/OftalmologiaEsportivaPage';
import CirurgiaRefrativaPage from './pages/Especialidades/CirurgiaRefrativaPage';
import MissoesSociaisPage from './pages/MissoesSociais';
import BlogPage from './pages/Blog';
import BlogPostDetailsPage from './pages/Blog/BlogPostDetails'

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {

  const [menuHidden, setMenuHidden] = useState(true);

  function toggleMenu() {
    setMenuHidden(!menuHidden);
  }

  useEffect(_ => {
    if (menuHidden) {
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }
  }, [menuHidden])

  return (
    <Router>
      <ScrollToTop />
      <MenuSection hidden={menuHidden} toggleMenu={toggleMenu} />
      <HeaderSection toggleMenu={toggleMenu} />
      <Switch>
        <Route path="/quem_sou"><QuemSouPage /></Route>
        <Route path="/cuidado_ao_idoso"><CuidadoAoIdosoPage /></Route>
        <Route path="/oftalmologia_esportiva"><OftalmologiaEsportivaPage /></Route>
        <Route path="/cirurgia_refrativa"><CirurgiaRefrativaPage /></Route>
        <Route path="/missoes_sociais"><MissoesSociaisPage /></Route>
        <Route path="/blog/post/:id"><BlogPostDetailsPage /></Route>
        <Route path="/blog"><BlogPage /></Route>
        <Route path="/home"><RootPage /></Route>
        <Route path="/"><RootPage /></Route>
      </Switch>
      <ContatoSection />
      <MinhasRedesSection />
      <FooterSection />
    </Router>
  );
}

export default App;