import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Section from '../../components/Section';
import { fetchPosts } from '../../wordpress/api';

import './styles.css';

function BlogSection() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {

        fetchPosts(3)
            .then(response => {
                setPosts(response.data)
            });
    }, []);

    return (
        <Section id="blog" className="section-blog">
            <h2>Blog<span className="green-dot">.</span></h2>
            <div>Acesse conteúdos exclusivos sobre prevenção de doenças e cuidados com seus olhos.</div>
            <div className="section-blog-posts">
                {posts.map((post, index) => (
                    <Link to={"/blog/post/" + post.id} key={index} className="section-blog-post">

                        <div className="section-blog-post-image">
                            <img src={post.imageUrl} />
                            <div className="section-blog-backdrop">
                                <span>Leia mais</span>
                            </div>
                        </div>
                        <div className="section-blog-post-title">
                            <h3>{post.title}</h3>
                        </div>

                    </Link>
                ))}
            </div>
            <div className="see-more"><Link to="/blog">Veja todas as postagens</Link></div>
        </Section>
    )
}
export default BlogSection;