import Section from '../../components/Section';
import foto_barbara from '../../img/Foto-Barbara.png';

import './styles.css';

function AboutSection() {

    return (
        <Section id="about" className="section-about">
            <div className="section-about-image">
                <img src={foto_barbara} />
            </div>
            <div className="section-about-text">
                <h2>__ Sobre mim</h2>
                <p>
                    Sou Bárbara Clemente, médica formada pela PUC SP e com especialização em Catarata pelo Instituto da Visão IPEPO. Fiz minha residência em oftalmologia no Hospital de Olhos (HOA) - Goiânia. Realizei um treinamento no setor de catarata focado em técnicas cirúrgicas na Índia (Prasad Eye Institute) e coordenei um projeto da Oftalmologia Humanitária como cirurgiã de catarata que atende a população de cidades ribeirinhas no Amazonas.
                </p>
                <p>
                    Faço atendimentos em São Joaquim da Barra (interior de SP). Minha missão como oftalmologista é melhorar a visão das pessoas!
                </p>
            </div>
        </Section>
    )
}

export default AboutSection;