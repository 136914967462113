//https://developer.wordpress.org/rest-api/using-the-rest-api/pagination/
const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_URL = BASE_URL + '/?rest_route=/wp/v2';
const LIMIT_PER_PAGE = 'per_page';
const PAGE = 'page';

function fetchPosts(limit, page) {

    let url = `${API_URL}/posts&${LIMIT_PER_PAGE}=${limit}`;
    if (page) {
        url += `&${PAGE}=${page}`;
    }

    let totalRecords = 0
    let totalPages = 0

    return fetch(url)
        .then(response => {

            const headers = response.headers
            totalRecords = headers.get('X-WP-Total')
            totalPages = headers.get('X-WP-TotalPages')

            return response.json()
        })
        .then(jsonArray => {

            return {
                totalPages: Number.parseInt(totalPages),
                totalRecords: Number.parseInt(totalRecords),
                data: jsonArray.map(json => ({
                    id: json['id'],
                    title: json['title']['rendered'],
                    imageUrl: json['fimg_url']
                }))
            };
        })
}

function fetchPost(postId) {

    const url = `${API_URL}/posts/${postId}`;

    return fetch(url)
        .then(response => response.json())
        .then(json => {

            return {
                id: json['id'],
                title: json['title']['rendered'],
                date: json['date'],
                lastModification: json['modified'],
                imageUrl: json['fimg_url'],
                content: json['content']['rendered']
            };
        })
}

export {
    fetchPosts
    , fetchPost
}